import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { KonvertStringToBase64String } from "../../models/KonvertStringToBase64String"; 

export default class StringToBase64StringStore {

    loading = false;    
    inputString: string | undefined = undefined;
    outputString: string | undefined = undefined;

    constructor(){
        makeAutoObservable(this)
    }


    convert = async (input: KonvertStringToBase64String) => {
        this.loading = true;
        try {
            console.log("passed value: " + input.fromString);
            let res = await agent.Konvert.stringToBase64(input);
            if (res === null || res.trim() === ""){
                console.log("empty string result");
            }else {
                console.log("response: " + res);

                runInAction(() => {    
                    this.inputString = input.fromString;
                    this.outputString = res;            
                    this.loading = false;
                });  

                console.log("outputString : " + this.outputString);
            }           
        } catch (error) {
            console.log(error);
        }
    };
} 
