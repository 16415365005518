import React from 'react';
import logo from '../logo.svg';
import './App.css';
import { Outlet, useLocation } from 'react-router-dom';
import HomePage from '../features/home/HomePage';
import NavBar from './Navbar';
import { Container } from 'semantic-ui-react';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <p>Om</p>
//         <p>Shri Ganeshay Nahamh</p>
//         <p>Jai shri krishna</p>
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload!!!.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

function App() {
  const location = useLocation();

  return ( 
    <> 
      {location.pathname === '/home' ? <HomePage /> : (
        <>
          <NavBar />
          <Container  style={{ marginTop: '7em' }}>
            <Outlet />
          </Container>
        </>
      )}
    </>
  );
}

export default App;
