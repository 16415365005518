import { RouteObject, createBrowserRouter } from "react-router-dom";
import App from "../../layout/App";
import HomePage from "../../features/home/HomePage"; 
import StringToBase64String from "../../features/converters/stringToBase64String/stringToBase64String";

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            { path: '', element: <HomePage /> },
            { path: 'strToBase64Str', element: <StringToBase64String /> }
            //to show all activities on dashboard route
            // {path: 'activities', element:<ActivityDashboard/>}, 

            // //select a single activity on route    
            // {path: 'activities/:id', element:<ActivityDetails/>}, 

            // //create new activity route
            // //Here we must specify 'key' because we are using same 'ActivityForm' element for both Create and Edit/Update/Manage
            // {path: 'createactivity', element:<ActivityForm key='create'/>},  

            // //edit/update existing actvity
            // //Here we must specify 'key' because we are using same 'ActivityForm' element for both Create and Edit/Update/Manage
            // {path: 'manage/:id', element:<ActivityForm key='manage'/>},

            // {path: 'login', element:<LoginForm/>},

            // {path: 'errors', element:<TestErrors/>},
            // {path: 'not-found', element:<TestErrors/>},
            // {path: 'server-error', element:<TestErrors/>},
            // {path: 'server-error', element:<TestErrors/>},
            // {path: '*', element:<Navigate replace to='/not-found'/>},
        ]
    }
]
export const router = createBrowserRouter(routes);