import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './layout/App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import NavBar from './layout/Navbar';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { router } from './app/router/Routers';
import { StoreContext, store } from './app/stores/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
   <React.StrictMode>
    {/* Here we are registering our Store Context to use in our app globally and adding <App/> as child */}
    <StoreContext.Provider value={store}> 
       {/* <BrowserRouter>
        <App />
       </BrowserRouter>        */}
       <RouterProvider router={router}/>
    </StoreContext.Provider> 
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
