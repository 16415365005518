import { Container, Divider, Grid, Header, Icon, Segment, Image, Button, Form, Label, GridColumn, TextArea } from "semantic-ui-react";
import BodyForm from "../BodyForm";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { KonvertStringToBase64String } from "../../../models/KonvertStringToBase64String";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import LoadingComponent from "../../../layout/LoadingComponent";
import CustomTextArea from "../../../app/common/forms/options/CustomTextArea";
import { values } from "mobx";


export default observer(function StringToBase64String() {

    const { stringToBase64StringStore } = useStore();
    const { loading, convert, inputString, outputString } = stringToBase64StringStore;

    const [input, setInput] = useState<KonvertStringToBase64String>({
        fromString: stringToBase64StringStore.inputString ?? ''
    });

    const validationSchema = Yup.object({
        fromString: Yup.string().required('Input text cannot be empty.')
    })

    function handleFormSubmit(input: KonvertStringToBase64String) {
        console.log("submit pressed");
        convert(input).then(() => {
        });
    }


    if (loading) return <LoadingComponent content='Loading ABC...' />

    function handleCopyButton() {
        var stringToCopy = outputString ?? "";
        navigator.clipboard.writeText(stringToCopy);
    }


    return (
        <Segment>
            <Header>
                {/* <Image size='massive' src='/assets/logo.png' style={{ marginBottom: 12 }} /> */}
                Convert String to Base64 String
            </Header>

            <Grid columns='three' divided>
                <Grid.Row>
                    <Grid.Column width='8'>
                        <Formik
                            validationSchema={validationSchema}
                            //enableReinitialize
                            initialValues={input}
                            onSubmit={values => handleFormSubmit(values)}>
                            {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                    <CustomTextArea defaultValue="" placeholder='Type or paste your text here' name="fromString" rows={0} />
                                    <Button
                                        disabled={isSubmitting || !dirty || !isValid}
                                        loading={loading} floated='right' positive type='submit' content='Submit' />

                                    <Button
                                        loading={loading} type="reset" content='Reset' />
                                </Form>
                            )}
                        </Formik>
                    </Grid.Column>
                     
                    <Grid.Column width='8'>
                        <Grid.Row>
                            <Button onClick={handleCopyButton}>
                                <Button.Content>
                                    <Icon name='copy' />
                                </Button.Content>
                            </Button>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: '1em' }}>
                            <p className="mt-3" style={{ wordWrap: "break-word" }}>
                                {outputString}
                            </p>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
})