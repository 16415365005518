import { observer } from 'mobx-react-lite';
import React from 'react';
import { Menu } from 'semantic-ui-react';
import { useStore } from '../app/stores/store';
import { NavLink } from 'react-router-dom';
import AppInfo from '../models/AppInfo';

export default observer(function NavBar() {
    var appInfo = new AppInfo();

    return ( 
        <Menu>
            <Menu.Item as={NavLink} to='/' header>
                <img src="/assets/logo.png" alt="logo" style={{ marginRight: 10 }}></img>
                {appInfo.appName}
            </Menu.Item>
            <Menu.Item as={NavLink} to='/strToBase64Str' name='String to Base64' />
            <Menu.Item as={NavLink} to='/Contact Us' name='Contact us' />
        </Menu>
    )
})