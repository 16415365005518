import { createContext, useContext } from "react";
import StringToBase64StringStore from "./stringToBase64StringStore";

interface Store{
    // activityStore: ActivityStore,
    // commonStore: CommonStore,
    stringToBase64StringStore: StringToBase64StringStore
}

export const store: Store = {
    // activityStore: new ActivityStore(),
    // commonStore: new CommonStore(),
    stringToBase64StringStore: new StringToBase64StringStore()
}


//We are registering this StoreContext in Index.tsx file to become available to our entire app globally
export const StoreContext = createContext(store);

export function useStore(){
    return useContext(StoreContext);
}