import axios, { AxiosResponse } from "axios";
import { request } from "http";
import { KonvertStringToBase64String } from "../../models/KonvertStringToBase64String";

//hard coded url
//axios.defaults.baseURL = "http://localhost:5134/api";

//url from environment file
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// const sleep = (delay: number) => {
//     return new Promise((resolve) => {
//       setTimeout(resolve, delay);
//     })
//   }

// axios.interceptors.response.use(async response => {
//     try{
//         await sleep(1000);
//         return response;
//     }catch(error){
//         console.log(error);
//         return await Promise.reject(error);
//     } 
// })

//getting response body
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

//creating request object and passing API url then we are passing responsBody to read response
const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post:<T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.get<T>(url).then(responseBody)
}

const Konvert = {
    stringToBase64: (value: KonvertStringToBase64String) => requests.post<string>('/convert', value)
}


const agent = {
    Konvert
}

export default agent; 
